<template>
  <div>
    <div class="thanks-wrapper row justify-content-md-center pt-5">
      <div class="col-md-6 p-3" :class="$parent.isDesktop ? 'd-flex' : ''">
        <div class="col text-center">
          <img class="mt-3" src="https://tss-static-images.gumlet.io/thank-you/soul1.png" />
        </div>
        <div class="col">
          <h2 class="mt-3 app-title text-center"><b>{{message}}</b></h2>
        </div>
      </div>
    </div> 
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters({
      message: 'surveyOrderMsg'
    })
  }
}
</script>
<style scoped>
</style>
